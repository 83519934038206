<template>
  <div>
    <div class="page-share-wrap">
      <div class="page-share" id="pageShare">
        <ul class="reset share-icons">
          <li>
            <a class="weibo share-sns" target="_blank"
               :href="'http://service.weibo.com/share/share.php?url='+this.now_link+'&title=《'+title+'》 —落迁忆&pic=http://127.0.0.1:8081/assets/images/avatar.jpg'"
               data-title="微博">
              <i class="icon icon-weibo"></i>
            </a>
          </li>
          <li>
            <a class="weixin share-sns wxFab" data-title="微信" @mouseenter="is_hoverd=true" @mouseleave="is_hoverd=false">
              <i class="icon icon-weixin"></i>
            </a>
          </li>
          <li>
            <a class="qq share-sns" target="_blank"
               :href="'http://connect.qq.com/widget/shareqq/index.html?url='+this.now_link+'&title=《'+title+'》 — 落迁忆&source=落迁忆 | 纵有疾风起，人生不言弃 | 隐约雷鸣，阴霾天空，即使天无雨，我亦留此地。'"
               data-title=" QQ">
              <i class="icon icon-qq"></i>
            </a>
          </li>
          <li>
            <a class="facebook share-sns" target="_blank"
               :href="'http://www.facebook.com/sharer/sharer.php?u='+this.now_link" data-title=" Facebook">
              <i class="icon icon-facebook"></i>
            </a>
          </li>
          <li>
            <a class="twitter share-sns" target="_blank"
               :href="'http://twitter.com/intent/tweet?text='+this.now_link"
               data-title=" Twitter">
              <i class="icon icon-twitter"></i>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="is_hoverd" class="wechat-qrcode">

        <div class="qrcode" title="https://blog.yuanpei.me/links">
          <canvas width="100" height="100" style="display: none;"></canvas>
          <img  style="-webkit-user-select: none;margin: auto;cursor: zoom-out;"
               :src="'https://api.qrserver.com/v1/create-qr-code/?data='+this.now_link" >
        <div class="help">
          <p>微信扫描二维码</p></div>
        </div>
      </div>
      <a v-waves href="javascript:;" id="shareFab" class="page-share-fab waves-effect waves-circle">
        <i class="icon icon-share-alt icon-lg"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "shareFeb",
  data() {
    return {
      now_link: '',
      is_hoverd: false
    }
  },
  props: {
    title: {
      type: String,
      default: '落迁忆de博客'
    }
  },
  mounted() {
    /*************分享小按钮********************/
    $('#shareFab').click(function () {
      if ($('#pageShare').hasClass('in')) {
        $('#pageShare').removeClass('in')
      } else {
        $('#pageShare').addClass('in')
      }
    })
    this.now_link = document.location.href.replace('localhost', '192.168.43.142')
  },
}
</script>

<style scoped>
.reset li > a {
  color: white;
}

.wechat-qrcode {
  border: 1px solid #eee;
  position: absolute;
  z-index: 9;
  top: -240px;
  left: -165px;
  width: 160px;
  height: 160px;
  color: #666;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 10px #aaa;
  transition: all 200ms;
  -webkit-tansition: all 350ms;
  -moz-transition: all 350ms;
}

.qrcode {
  width: 105px;
  margin: 10px auto;
}
</style>
